import { defineStore } from "pinia";

export const useIataStore = defineStore({
  id: "iata",

  state: () => ({
    iata: null,
  }),
  getters: {
    getIata: (state) => state.iata,
  },
  actions: {
    SET_IATA(iata: any) {
      this.iata = iata;
    },
  },

  persist: {
    storage: persistedState.sessionStorage,
  },
});
